import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Section, Row, Col, H2, Tabs, Tab, Table, Thead, Tbody, Tr, Th, Td, Button, Modal, NavigationBar, NavigationLink, JS } from 'im-ui-pack';
import { request, ContentTypes } from '../../../../store/api/api';
// import items from '../../../../store/items/ItemsReducers';
import meet_data from './../../../../store/mock/meet.json';
import ModalCreateMeet from './modalCreateMeet/ModalCreateMeet';
import { useSelector } from 'react-redux';

function MeetOverview() {
  const { company } = useSelector((state: RootState) => state.default.company);
  const { branch_id } = useParams<any>();
  const [meets, setMeets] = useState();
  const [branch, setBranch] = useState();

  const [componentModal, setComponentModal] = useState<any|undefined>(undefined);

  useEffect(() => {
    if(!meets) {
      getBranch();
      getMeets();
    }
    console.log('branch: ', branch);
  });

/*
  const getMeetTime = (meet:any) => {
    return meet.map((meetItem)=>{
      return (
        <div>
          <div>{meetItem.time_begin}</div>
          <div>{meetItem.time_end}</div>
        </div>
      );
    });
  }

  const getMeetEmployeeSubscribers = (meet: any) => {
    let total_employee_subscribers = 0;
    meet.forEach(meetItem => {
      total_employee_subscribers += parseFloat(meetItem.employee_subscribers);
    })
    return total_employee_subscribers;
  }

  const getAssessmentSubscription = (item: any) => {
    return (
      <div>
        <div>{item.employee_subscription}/{item.employee_subscription_total}</div>
        <div>{(item.employee_subscription * 100)/item.employee_subscription_total }% Completado</div>
      </div>
    );
  }

  const getTimeBeginEnd = (item: any) => {
    return (
      <div>
        <div>{item.time_begin}</div>
        <div>{item.time_end}</div>
      </div>
    );
  }*/

  function showModalCreateMeet() {
    setComponentModal(<ModalCreateMeet close={()=>closeModal()} save={(data)=>saveModal(data)} />);
  }

  async function createMeet(data:any) {
    const { company_id } = company;
    const data_param = {
      "company_id": company_id,
      "branch_id": branch_id,
      "meet_type": data.meet_type,
      "meet_time_start": data.meet_time_start.toString(),
      "meet_time_end": data.meet_time_end.toString(),
      "meet_sessions": data.meet_sessions,
      "assessment_id": "ct8BWbXLoM7srbkfyZeVS9"
    }

    const endpoint = `/meet/create`;
    try {
      const res = await request('POST', endpoint, data_param, { 'content-type': ContentTypes.json });
      getMeets();
    } catch (err) {
      console.log(err)
    }
  };

  function saveModal(data) {
    console.log('Save data: ',data);
    createMeet(data);
    setComponentModal(null);
  }

  function closeModal() {
    setComponentModal(null);
  }

  // Get branch related to the meet
  async function getMeets() {
    const data = {
      "branch_id" : branch_id
    };
    const endpoint = `/meet/list`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
      console.log('>>>>> Meet result: ', result);
      setMeets(result);
    } catch (err) {
      console.log(err)
    }
  };

  async function getBranch() {
    const data = {
      "branch_id" : branch_id
    };
    const endpoint = `/branch`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
      setBranch(result);
    } catch (err) {
      console.log(err)
    }
  };

  return ( 
    <div>
      <Section>
        <Row>
          <Col>
            <H2>Citas</H2>
            <NavigationBar>
              <NavigationLink href="https://admin.improovia.com/">Admin</NavigationLink>
              <NavigationLink href="/">Encuestas</NavigationLink>
              <NavigationLink href="/dashboard/assessment/branch/1">Punto de servicio</NavigationLink>
            </NavigationBar>
          </Col>
          <Col className='align-right'>
            <Button className='primary' label='Agregar cita' action={()=> showModalCreateMeet()}/>  
          </Col>
        </Row>
        {
          (branch) ? (
          <Row>
            <Col>Sucursal: {branch.branch_name}</Col>
            <Col>Empleados: {branch.branch_employees}</Col>
          </Row>) : null
        }
        <Row>
          <Col>

            <Table>
              <Thead>
              <Tr>
                <Th>Fecha de inicio</Th><Th>Número de sesiones</Th><Th>Web</Th><Th></Th>
              </Tr>
              </Thead>
              <Tbody>
              {(meets) ? (
                meets.map((item: any)=> {
                  const meet_sessions = JSON.parse(item.meet_sessions);
                  return (
                    <Tr key={item.meet_id}>
                      <Td>{JS.meetFormat.interval(item.meet_time_start,item.meet_time_end)}</Td>
                      <Td>{meet_sessions.length}</Td>
                      <Td><a href={`https://employee.improovia.com/booking/${item.branch_id}`} target="_blank">Link de citas</a></Td>
                      <Td>
                        <div className='position-right btn-group'>
                          <Link to={`/dashboard/assessment/session/${item.meet_id}`}>
                            <Button className='primary' label='Sesiones' action={()=>null}/>
                          </Link>
                        </div>
                      </Td>
                    </Tr>
                  )
                })
              ) : null
              }
              </Tbody>
            </Table>

          </Col>
        </Row>
      </Section>
      <Modal close={()=>closeModal()} component={componentModal}/>
    </div>
  );
}

export default MeetOverview;