import React from 'react';
import { Container } from 'im-ui-pack';
import './FullLayout.css';

function PublicLayout(props: any) {

  return (
    <div className="full-layout bg-public">
      <Container>
        {props.children}
      </Container>
    </div>
  );
}

export default PublicLayout