import React, { useState } from 'react';
import { Row, Col, Notification, Input, Button } from 'im-ui-pack';
import es_states from '../../../../../store/mock/es_states.json';

const ModalCreateBranch = (props: any) => {
  const { close, save } = props;
  const [formData, setFormData] = useState({
    branch_name: '',
    branch_employees: '',
    branch_location_address: '',
    branch_location_city: '',
    branch_location_state: ''
  });

  const saveModal = () => {
    save(formData);
  }

  const closeModal = () => {
    close();
  }

  const handleInputChange = (event: any) => {
    setFormData({
      ...formData,
      [event.target.name] : event.target.value
    })
  }

  return (
    <div>
      <div className='modal-header'>Agregar sucursal</div>
      <div className='modal-content'>
        <Row>
          <Col>
            <Input type='text' name='branch_name' value={formData.branch_name} placeholder={'Nombre de sucursal'} onChange={handleInputChange}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <Input type='number' name='branch_employees' value={formData.branch_employees} placeholder={'Total de empleados a encuestar'} onChange={handleInputChange}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <Input type='text' name='branch_location_address' value={formData.branch_location_address} placeholder={'Dirección'} onChange={handleInputChange}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <Input type='text' name='branch_location_city' value={formData.branch_location_city} placeholder={'Ciudad'} onChange={handleInputChange}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <select className='inp' name='branch_location_state' value={formData.branch_location_state} onChange={handleInputChange}>
              {
                es_states.map((item, index)=>{
                  return (<option key={index} value={item.slug}>{item.state}</option>)
                })
              }
            </select>
            {/*<Input type='text' name='branch_location_state' value={formData.branch_location_state} placeholder={'Provincia'} onChange={handleInputChange}/>*/}
          </Col>
        </Row>
      </div>  
      <div className='modal-bottom'>
        <Button className='secondary-outline' action={()=>closeModal()} label='Cancelar'/>
        <Button className='primary' action={()=>saveModal()} label='Guardar'/>
      </div>
    </div>
  )
}

export default ModalCreateBranch;
