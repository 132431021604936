import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Section, Row, Col, Box, H2, H5, Button, LineDivisor, NavigationBar, NavigationLink, Notification, JS } from 'im-ui-pack';
import { request, ContentTypes } from '../../../../store/api/api';
// import items from '../../../../store/items/ItemsReducers';
import meet_data from './../../../../store/mock/meet.json';

function SessionRecord() {
  let history = useHistory();
  const { session } = meet_data[0];
  const { record_id, meet_id } = useParams<any>();
  const [record, setRecord] = useState(null);
  const [notification, setNotification] = useState({status: false, style: '', message: ''});
  const pain_list = [1,2,3,4,5,6,7,8,9,10];
  const symptoms_list = [
    {
      'label': 'Dolor de espalda',
      'value': '1'
    },
    {
      'label': 'Dolor de hombros',
      'value': '2'
    },
    {
      'label': 'Otros',
      'value': '3'
    }
  ]
  // const { session } = meet_data[0];
  const [formData, setFormData] = useState({
    record_status: '',
    pain: '',
    symptoms: '',
    comment: ''
  });

  useEffect(() => {
    if(!record) {
      getRecord();
    }
    console.log('formData: ',formData);
  });

  // Get branch related to the meet
  async function getRecord() {
    const data = {
      "record_id" : record_id
    };
    const endpoint = `/record`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
      console.log('>>>>> Record res: ', result);
      // console.log('result.record_form.pain: ', result.record_form.pain);
      const record_status = result.record_status ? result.record_status : '';
      const pain = result.record_form && result.record_form.pain ? result.record_form.pain : 0;
      const symptoms = result.record_form && result.record_form.symptoms ? result.record_form.symptoms : 0;
      const comment = result.record_form && result.record_form.comment ? result.record_form.comment : '';
      setFormData({
        record_status,
        pain: pain,
        symptoms: symptoms,
        comment: comment
      });
      setRecord(result);
    } catch (err) {
      console.log(err)
    }
  };

  async function handleSubmit() {
    const data = {
      "record_id": record_id,
      "record_status": formData.record_status,
      "record_form": {
        "pain": formData.pain,
        "symptoms": formData.symptoms,
        "comment": formData.comment
      }
    }
    const endpoint = `/record/update`;
    try {
      const response = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const {result, message_code} = response.data;
      if(result){       
        setNotification({
          status: true,
          className: 'success',
          message: 'La ficha se ha guardado correctamente.'
        });
      } else {
        setNotification({
          status: true,
          className: 'danger',
          message: 'No se han guardado los datos, si el problema persiste contacte con el equipo de soporte.'
        });
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name] : event.target.value
    })
  }

  const handleGoBack = () => {
    history.push(`/dashboard/assessment/session/${meet_id}`);
  }

  if(!record) {
    return (
      <div>
        <Section>
          <Row>
            <Col>
              <H2></H2>
              <NavigationBar>
                <NavigationLink href="https://admin.improovia.com/">Admin</NavigationLink>
                <NavigationLink href="/dashboard/">Citas</NavigationLink>
                <NavigationLink href="/dashboard/assessment/session/0">Sesiones</NavigationLink>
              </NavigationBar>
            </Col>
            <Col className='align-right'>
              Loading...
            </Col>
          </Row>
        </Section>
      </div>
    )
  }

  return ( 
    <div>
      <Section>
        <Row>
          <Col>
            <H2>Ficha: {record.employee_fullname}</H2>
            <NavigationBar>
              <NavigationLink href="https://admin.improovia.com/">Admin</NavigationLink>
              <NavigationLink href="/dashboard/">Citas</NavigationLink>
              <NavigationLink href="/dashboard/assessment/session/0">Sesiones</NavigationLink>
            </NavigationBar>
          </Col>
          <Col className='align-right'>
          </Col>
        </Row>
        <Row>
          <Col>Sucursal: {record.branch_name} / {record.branch_location_city} - {record.branch_location_city}</Col>
        </Row>
        <Row>
          <Col>Sesión: {JS.meetFormat.interval(record.record_time_start, record.record_time_end)}</Col>
        </Row>
        <Box>
          <Row>
            <Col>
              <H5>Informe empleado</H5>
            </Col>
          </Row>
            {(notification.status) ? (
                <div>
                  <Row>
                    <Col>
                      <Notification className={notification.className}>{notification.message}</Notification>
                    </Col>
                  </Row>
                  <div className='position-right btn-group'>
                    <Button className='primary' label='Volver' action={()=>handleGoBack()}/>
                  </div>
                </div>
              ) : 
              (
                <div>
                  <Row>
                    <Col><LineDivisor /></Col>
                  </Row>
                  <Row>
                    <Col className='w-20'>Atendido</Col>
                    <Col className='w-20'>
                      <select className='inp' name="record_status" onChange={(e)=>handleInputChange(e)}>
                        {JS.recordStatus.list().map((item)=>{
                          const record_status_selected = formData && formData.record_status===item.value ? true : false;
                          return (<option value={item.value} selected={record_status_selected}>{item.label}</option>)
                        })}
                      </select>
                    </Col>
                  </Row>
                  <Row>
                    <Col className='w-20'>Dolor</Col>
                    <Col className=''>
                      {pain_list.map((item)=>{
                        const pain_selected = formData && parseInt(formData.pain)===item ? true : false;
                        return (<span><input type="radio" id="pain" name="pain" value={item} checked={pain_selected} onChange={(e)=>handleInputChange(e)}/><label>{item}</label></span>)
                      })}
                    </Col>
                  </Row>
                  <Row>
                    <Col className='w-20'>Síntomas</Col>
                    <Col className='w-20'>
                      <div className='btn-group'>
                        {symptoms_list.map((item)=>{
                          const symptoms_checked = formData && formData.symptoms===item.value ? true : false;
                          return (
                            <div>
                              <input type="checkbox" id="symptoms" name="symptoms" checked={symptoms_checked} value={item.value} onChange={(e)=>handleInputChange(e)}/>
                              <label>{item.label}</label>
                            </div>
                          )
                        })}
                        {/*
                        <div>
                          <input type="checkbox" id="symptoms" name="symptoms" value="1" onChange={(e)=>handleInputChange(e)}/>
                          <label>Dolor de espalda</label>
                        </div>
                        <div>
                          <input type="checkbox" id="symptoms" name="symptoms" value="2" onChange={(e)=>handleInputChange(e)}/>
                          <label>Dolor de hombros</label>                
                        </div>
                        <div>
                          <input type="checkbox" id="symptoms" name="symptoms" value="3" onChange={(e)=>handleInputChange(e)}/>
                          <label>Otros</label>                
                      </div>*/}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className='w-20'>Observaciones</Col>
                    <Col><textarea placeholder='Comentario' id="comment" name="comment" value={formData.comment} onChange={(e)=>handleInputChange(e)}>{formData.comment}</textarea></Col>
                  </Row>
                  <div className='position-right btn-group'>
                    {/*<Button label='Cancel' action={()=>null}/>
                    <Button className='primary' label='Guardar' action={()=>handleSubmit()}/>*/}
                  </div>
                </div>
              )
            }

        </Box>
      </Section>
    </div>
  );
}

export default SessionRecord;