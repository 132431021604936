// import mockOffices from './../mock/offices.json';

const initialState = {
  items: [],
};

export default function items(state = initialState, action) {
  switch (action.type) {
    case 'SET_ITEMS':
      return {
        ...state,
        items: action.items
      };
    default:
      return state;
  }
}
