
export const ENV = process.env.REACT_APP_ENV;
export const PREFIX_URL = process.env.REACT_APP_PREFIX_URL;

export const API_URL = process.env.REACT_APP_API_URL;
export const API_URL_PAYMENT = process.env.REACT_APP_API_URL_PAYMENT;
export const API_URL_EMAIL = process.env.REACT_APP_API_URL_EMAIL;

// STRIPE
export const STRIPE_LOCALHOST_SERVER = process.env.REACT_APP_STRIPE_LOCALHOST_SERVER;
