import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, H4, Row, Notification } from 'im-ui-pack';

function CompanyEmployeeDown() {

  useEffect(() => {});

  return (
    <div>
      <Box>
        <Row>
          <Notification>No hay datos disponibles.</Notification>
        </Row>
      </Box>
    </div>
  )
}

export default CompanyEmployeeDown;
