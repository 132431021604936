/* Pages */
// import ErrorPage from '../containers/errorPage/ErrorPage';
import IRoute from '../models/route';

// AssessmentCompany
import AssessmentOverview from '../pages/private/assessment/overview/Overview';
import CreateAssessmentCompany from '../pages/private/assessment/createAssessment/CreateAssessment';
import PublicAssessmentCompany from '../pages/public/assessment/company/PublicAssessmentCompany';
import CreateCompany from '../pages/private/company/create/CreateCompany';

// Company
import BranchOverview from '../pages/private/branch/overview/Overview';

// Employee
import EmployeeOverview from '../pages/private/employee/overview/Overview';

// Meet
import MeetOverview from '../pages/private/meet/overview/Overview';

// Session
import SessionOverview from '../pages/private/session/overview/Overview';
import SessionRecord from '../pages/private/session/record/Record';

// Accounting
import AccountingOverview from '../pages/private/accounting/overview/Overview';
import PaymentProfessional from '../pages/private/accounting/paymentProfessional/PaymentProfessional';

// Private
// import Search from '../pages/private/search/Search';

/* Layouts */
// import PublicLayout from '../layouts/publicLayout/PublicLayout';
import DashboardLayout from '../layouts/dashboardLayout/DashboardLayout';
import FullLayout from '../layouts/fullLayout/FullLayout';

/* General */
import BranchPayment from '../pages/private/payment/overview/Overview';
import Report from '../pages/private/report/Overview';
import Profile from '../pages/private/profile/Profile';
import ChatOverview from '../pages/private/chat/overview/Overview';
// import PrivateLayout from '../layouts/privateLayout/PrivateLayout';

export const routes: IRoute[] = [
  {
    path: '/dashboard/chat',
    name: 'Chat Page',
    component: ChatOverview,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/profile',
    name: 'Profile Page',
    component: Profile,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/report',
    name: 'Reporte Page',
    component: Report,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/payment',
    name: 'Payment Page',
    component: BranchPayment,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/employee',
    name: 'Employee Page',
    component: EmployeeOverview,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/assessment/company/:assessment_id/:branch_id',
    name: 'Public Assessment Company Overview Page',
    component: PublicAssessmentCompany,
    layout: FullLayout,
    exact: true
  },
  {
    path: '/dashboard/assessment/create/',
    name: 'Create Assessment Company Page',
    component: CreateAssessmentCompany,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/assessment/session/record/:record_id',
    name: 'Session Record Overview Page',
    component: SessionRecord,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/assessment/session/:meet_id',
    name: 'Session Overview Page',
    component: SessionOverview,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/assessment/meet/:branch_id',
    name: 'Meet Overview Page',
    component: MeetOverview,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/assessment/branch/:assessment_id',
    name: 'Branch Overview Page',
    component: BranchOverview,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard',
    name: 'Assessment Overview Page',
    component: AssessmentOverview,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/company/create',
    name: 'Company Create Overview Page',
    component: CreateCompany,
    layout: FullLayout,
    exact: true
  },
  {
    path: '/',
    name: 'Assessment Overview Page',
    component: AssessmentOverview,
    layout: DashboardLayout,
    exact: true
  }
];