import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Section, Row, Col, H2, Table, Thead, Tbody, Tr, Th, Td, Button, Modal, NavigationBar, NavigationLink, JS } from 'im-ui-pack';
import { request, ContentTypes } from '../../../../store/api/api';
// import items from '../../../../store/items/ItemsReducers';
// import branch_assessment_data from './../../../../store/mock/branch_assessment.json';
import ModalCreateBranch from './modalCreateBranch/ModalCreateBranch';
import { useSelector } from 'react-redux';

function BranchOverview() {
  const { company } = useSelector((state: RootState) => state.default.company);
  const { assessment_id } = useParams();
  const [branchs, setBranchs] = useState();
  const [componentModal, setComponentModal] = useState<any|undefined>(undefined);

  useEffect(() => {
    if(!branchs) {
      getBranchs();
    }
  });

  const getMeetTime = (item:any) => {
    return (
      <div>
        <div>{JS.dateFormat.timestampToDate(item.branch_created_at)}</div>
        <div>{JS.dateFormat.timestampToDate(item.branch_created_at)}</div>
      </div>
    );
    /*return meet.map((meetItem)=>{
      return (
        <div>
          <div>{meetItem.time_begin}</div>
          <div>{meetItem.time_end}</div>
        </div>
      );
    });*/
  }

  const getMeetEmployeeSubscribers = (meet: any) => {
    let total_employee_subscribers = 0;
    meet.forEach(meetItem => {
      total_employee_subscribers += parseFloat(meetItem.employee_subscribers);
    })
    return total_employee_subscribers;
  }

  const getAssessmentSubscription = (item: any) => {
    return (
      <div>
        <div>{item.employee_subscription}/{item.employee_subscription_total}</div>
        <div>{(item.employee_subscription * 100)/item.employee_subscription_total }% Completado</div>
      </div>
    );
  }

  const getTimeBeginEnd = (item: any) => {
    return (
      <div>
        <div>{item.time_begin}</div>
        <div>{item.time_end}</div>
      </div>
    );
  }

  function showModalCreateBranch() {
    setComponentModal(<ModalCreateBranch close={()=>closeModal()} save={(modalData)=>saveModal(modalData)} />);
  }

  function saveModal(modalData) {
    console.log('Save: formData', modalData);
    createBranch(modalData);
    setComponentModal(null);
  }

  function closeModal() {
    setComponentModal(null);
  }

  async function getBranchs() {
    // event.preventDefault();
    /*const data = {
      "assessment_title": formData.assessment_title
    }*/
    const { company_id } = company;
    const data = {
      "company_id" : company_id
    };
    const endpoint = `/branch/list`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
      console.log('result: ', result);
      setBranchs(result);
    } catch (err) {
      console.log(err)
    }
  };

  async function createBranch(modalData) {
    // event.preventDefault();
    const { company_id } = company;
    const data = {
      "company_id": company_id,
      "branch_name": modalData.branch_name,
      "branch_employees": modalData.branch_employees,
      "branch_location_address": modalData.branch_location_address,
      "branch_location_city": modalData.branch_location_city,
      "branch_location_state": modalData.branch_location_state,
    }

    const endpoint = `/branch/create`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const { result } = res.data;
      if(result) {
        getBranchs();
      }
    } catch (err) {
      console.log(err)
    }
  };

  return ( 
    <div>
      <Section>
        <Row>
          <Col>
            <H2>Punto de servicio</H2>
            <NavigationBar>
              <NavigationLink href="https://admin.improovia.com/">Admin</NavigationLink>
              <NavigationLink href="/">Encuestas</NavigationLink>
            </NavigationBar>
          </Col>
          <Col className='align-right'>
            <Button className='primary' label='Agregar sucursal' action={()=> showModalCreateBranch()}/>
          </Col>
        </Row>
        <Row>
          <Col>

            <Table>
              <Thead>
              <Tr>
                <Th>Puntos de venta</Th>
                {/*<Th>Fecha de cita</Th>*/}
                {/*<Th>Número de empleados</Th>*/}
                  <Th>Encuesta</Th>
                  <Th>Fecha de creación</Th>
                <Th></Th>
              </Tr>
              </Thead>
              <Tbody>
              {branchs ? (
                branchs.map((item: any)=> {
                  return (
                    <Tr key={item.branch_id}>
                      <Td>{item.branch_name}</Td>
                      {/*<Td>{getMeetTime(item)}</Td>*/}
                      {/*<Td>{item.branch_employees}</Td>*/}
                      <Td><Link to={`/assessment/company/${assessment_id}/${item.branch_id}`}>Link de encuesta</Link></Td>
                      <Td>{JS.dateFormat.timestampToDate(item.branch_created_at)}</Td>
                      <Td>
                        <div className='position-right btn-group'>
                          <Link to={`/dashboard/assessment/meet/${item.branch_id}`}><Button className='primary' label='Citas' action={()=>null}/></Link>
                        </div>
                      </Td>
                    </Tr>
                  )
                })                
              ): null

              }
              </Tbody>
            </Table>

          </Col>
        </Row>
      </Section>
      <Modal close={()=>closeModal()} component={componentModal}/>
    </div>
  );
}

export default BranchOverview;