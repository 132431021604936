import { combineReducers } from 'redux';

// import booking from './booking/BookingReducers';
import items from './items/ItemsReducers';
import company from './company/CompanyReducers';

export default combineReducers({
	items,
	company,
});
