import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Section, Row, Col, H2, Tabs, Tab } from 'im-ui-pack';
import { requestSwitcherAPI, ContentTypes } from '../../../../store/api/api';
// import items from '../../../../store/items/ItemsReducers';
import CompanyReportGeneral from './tab/general/General';
import CompanyEmployeeDown from './tab/employeeDown/EmployeeDown';

function CompanyOverview() {

  useEffect(() => {});

  return ( 
    <div>
      <Section>
        <Row>
          <Col>
            <H2>Reporte</H2>
          </Col>
          <Col className='align-right'>
          </Col>
        </Row>
        <Row>
          <Col>
          
            <Tabs>
              <Tab data='General'>
                <div><CompanyReportGeneral /></div>
              </Tab>
              <Tab data='Reporte de bajas'>
                <div><CompanyEmployeeDown /></div>
              </Tab>
            </Tabs>
          
          </Col>
        </Row>
      </Section>
    </div>
  );
}

export default CompanyOverview;