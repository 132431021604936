import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Section, Row, Col, H2, Tabs, Tab, Table, Thead, Tbody, Tr, Th, Td, Button, NavigationBar, NavigationLink, JS } from 'im-ui-pack';
import { request, ContentTypes } from '../../../../store/api/api';
// import items from '../../../../store/items/ItemsReducers';
import meet_data from './../../../../store/mock/meet.json';

function SessionOverview() {
  const { meet_id } = useParams<any>();
  const [records, setRecords] = useState();
  const [meet, setMeet] = useState();

  useEffect(() => {
    if(!records) {
      getRecords();
      getMeet();
    }
    console.log('meet: ', meet);
  });

  const getStatus = (status: string) => {
    switch (status) {
      case 'in_process':
        return 'En proceso';
        break;
      case 'attended':
        return 'Atendido';
        break;
      case 'absent':
        return 'Ausente';
        break;
      case 'not_attended':
        return 'No atendido';
        break;
      default:
        break;
    }
  }

/*
  const getMeetTime = (meet:any) => {
    return meet.map((meetItem)=>{
      return (
        <div>
          <div>{meetItem.time_begin}</div>
          <div>{meetItem.time_end}</div>
        </div>
      );
    });
  }

  const getMeetEmployeeSubscribers = (meet: any) => {
    let total_employee_subscribers = 0;
    meet.forEach(meetItem => {
      total_employee_subscribers += parseFloat(meetItem.employee_subscribers);
    })
    return total_employee_subscribers;
  }

  const getAssessmentSubscription = (item: any) => {
    return (
      <div>
        <div>{item.employee_subscription}/{item.employee_subscription_total}</div>
        <div>{(item.employee_subscription * 100)/item.employee_subscription_total }% Completado</div>
      </div>
    );
  }

  const getTimeBeginEnd = (item: any) => {
    return (
      <div>
        <div>{item.time_begin}</div>
        <div>{item.time_end}</div>
      </div>
    );
  }*/

  // Get branch related to the meet
  async function getRecords() {
    const data = {
      "meet_id" : meet_id
    };
    const endpoint = `/record/list`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
      console.log('>>>>> Sessions result: ', result);
      setRecords(result);
    } catch (err) {
      console.log(err)
    }
  };

  async function getMeet() {
    const data = {
      "meet_id" : meet_id
    };
    const endpoint = `/meet`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
      setMeet(result);
    } catch (err) {
      console.log(err)
    }
  };

  return ( 
    <div>
      <Section>
        <Row>
          <Col>
            <H2>Sesiones</H2>
            <NavigationBar>
              <NavigationLink href="https://admin.improovia.com/">Admin</NavigationLink>
              <NavigationLink href="/">Encuestas</NavigationLink>
              <NavigationLink href="/dashboard/assessment/branch/1">Punto de servicio</NavigationLink>
              <NavigationLink href="/dashboard/assessment/meet/1">Cita</NavigationLink>
            </NavigationBar>
          </Col>
          <Col className='align-right'>
          </Col>
        </Row>
        {meet ? (
          <div>
            <Row>
              <Col>Punto de servicio: {meet.branch_name}</Col>
              <Col>Empleados: {meet.branch_employees}</Col>
            </Row>
            <Row>
              <Col>Dirección: {`${meet.branch_location_address} / ${meet.branch_location_city}`}</Col>
            </Row>
          </div>) : null
        }
        <Row>
          <Col>

            <Table>
              <Thead>
              <Tr>
                <Th>Nombre empleado</Th><Th>Turno</Th><Th>Estado</Th><Th></Th>
              </Tr>
              </Thead>
              <Tbody>
              { records ? (
                  records.map((item: any)=> {
                    return (
                      <Tr key={item.meet_id}>
                        <Td>{item.employee_fullname}</Td>
                        <Td>{JS.meetFormat.interval(item.record_time_start,item.record_time_end)}</Td>
                        <Td>{JS.recordStatus.recordStatusToText(item.record_status)}</Td>
                        <Td>
                          <div className='position-right btn-group'>
                            <Link to={`/dashboard/assessment/session/record/${item.record_id}`}>
                              <Button className='primary' label='Ficha' action={()=>null}/>
                            </Link>
                          </div>
                        </Td>
                      </Tr>
                    )
                  })
                ) : null 
              }
              </Tbody>
            </Table>

          </Col>
        </Row>
      </Section>
    </div>
  );
}

export default SessionOverview;