import React, { useState, useEffect } from 'react';
import { Section, Row, Col, H3, H4, Box, Input, Notification, JS } from 'im-ui-pack';
import { request, ContentTypes } from '../../../../store/api/api';
import es_states from '../../../../store/mock/es_states.json';
import './CreateCompany.css';
import { useHistory } from 'react-router-dom';

function CreateCompany() {
  const history = useHistory();
  const session = JS.getSession();
  const { account_firstname, account_lastname, account_email } = session.account;
  const [notification, setNotification] = useState({status: false, style: '', message: ''});
  const company_manager_fullname = `${account_firstname} ${account_lastname}`
  const company_manager_email = account_email;

  const [formData, setFormData] = useState({
    company_name: '',
    company_manager_fullname: company_manager_fullname,
    company_manager_email: company_manager_email,
    company_manager_tel: '',
    company_location_address: '',
    company_location_city: '',
    company_location_state: '',
    company_tax_id: '',
    company_contract_email: '',
    company_payment_time: '30',
    company_payment_type: 'copay'
  });

  useEffect(() => {

  });

  async function handleSubmit(event: any) {
    event.preventDefault();
    //setLoading(true);
    if((formData.company_name==='') ||
      (formData.company_manager_fullname==='') ||
      (formData.company_location_address==='') ||
      (formData.company_location_city==='') ||
      (formData.company_location_state==='') ||
      (formData.company_tax_id==='') ||
      (formData.company_contract_email==='') ||
      (formData.company_payment_time==='') ||
      (formData.company_payment_type==='')) {
        setNotification({
          status: true,
          className: 'danger',
          message: 'Debe completar todos los campos.'
        });
    }
    else {
      const session = JS.getSession();
      const date_birthday = new Date(formData.company_manager_birthday);
      const company_manager_birthday = JS.dateFormat.dateToTimestamp(date_birthday.getTime());
      const manager_birthday = Math.floor(company_manager_birthday/1000);
      const data = {
        "company_name": formData.company_name,
        "company_status": "inactive",
        "company_branch_total": "0",
        "company_manager_fullname": formData.company_manager_fullname,
        "company_manager_email": formData.company_manager_email,
        "company_manager_tel": formData.company_manager_tel,
        "company_manager_birthday": manager_birthday.toString(),
        "company_location_address": formData.company_location_address,
        "company_location_city": formData.company_location_city,
        "company_location_state": formData.company_location_state,
        "company_tax_id": formData.company_tax_id,
        "company_contract_email": formData.company_contract_email,
        "company_payment_time": formData.company_payment_time,
        "company_payment_type": formData.company_payment_type,
        "company_admin_accounts": [{"account_id": session.account.account_id}]
      };
  
      const endpoint = `/company/create`;
      try {
        const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
        const result = res.data;
        console.log('Create Company: result: ',result);
        //setCreateCompanySuccess(true);
        history.push('/dashboard')
      } catch (err) {
        console.log(err)
      }
    }
  };

  const handleInputChange = (event: any) => {
    setFormData({
      ...formData,
      [event.target.name] : event.target.value
    })
  }

  return ( 
    <div className='wrapper-assessment'>
      <Box>
        <Section className='max-width'>
          <Row>
            <Col>
              <H3>Agregar empresa</H3>
            </Col>
          </Row>
        <form onSubmit={ e =>handleSubmit(e)}>
          {(notification.status) ? (
            <Row>
              <Col>
                <Notification className={notification.className}>{notification.message}</Notification>
              </Col>
            </Row>) : null
          }
          <Row>
            <Col><H4>Empresa</H4></Col>
          </Row>
          <Row>
            <Col>
              Nombre de empresa
              <Input type='text' name='company_name' value={formData.company_name} placeholder={'Nombre de empresa'} onChange={handleInputChange} />
            </Col>
          </Row>
          <Row>
            <Col>
              Dirección
              <Input type='text' name='company_location_address' value={formData.company_location_address} placeholder={'Dirección fiscal'} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col>
              NIF
              <Input type='text' name='company_tax_id' value={formData.company_tax_id} placeholder={'NIF'} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col>
              Ciudad
              <Input type='text' name='company_location_city' value={formData.company_location_city} placeholder={'Ciudad'} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col className='w-40'>
              Provincia
              <select className='inp' name='company_location_state' value={formData.company_location_state} onChange={handleInputChange}>
                {
                  es_states.map((item, index)=>{
                    return (<option key={index} value={item.slug}>{item.state}</option>)
                  })
                }
              </select>
            </Col>
          </Row>
          <Row>
            <Col><H4>Responsable</H4></Col>
          </Row>
          <Row>
            <Col>
              Nombre completo
              <Input type='text' name='company_manager_fullname' value={formData.company_manager_fullname} placeholder={'Nombre completo'} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col>
              Teléfono
              <Input type='text' name='company_manager_tel' value={formData.company_manager_tel} placeholder={'Teléfono'} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col>
              Email
              <Input type='text' name='company_manager_email' value={formData.company_manager_email} placeholder={'Email'} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col className='w-30'>
              Fecha de nacimiento
              <Input type='date' name='company_manager_birthday' value={formData.company_manager_birthday} placeholder={'Fecha de nacimiento'} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col><H4>Acuerdos y contratos</H4></Col>
          </Row>
          <Row>
            <Col>
              Email de resposable de pagos
              <Input type='text' name='company_contract_email' value={formData.company_contract_email} placeholder={'Email del responsable de pagos'} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col>
              Plazos de pago
              <select className='inp' name='company_payment_time' value={formData.company_payment_time} onChange={handleInputChange}>
                <option value='30'>A 30 días</option>
                <option value='60'>A 60 días</option>
                <option value='90'>A 90 días</option>
              </select>
            </Col>
            <Col>
              Tipo de pago
              <select className='inp' name='company_payment_type' value={formData.company_payment_type} onChange={handleInputChange}>
                <option value='copay'>Copago</option>
                <option value='pay'>Pago</option>
                <option value='free'>Gratis</option>
              </select>
            </Col>
          </Row>
          {/*<Row>
            <Col>Documentos</Col>
            <Col>
              <Button className='btn-secondary' action={()=>null} label='Subir'></Button>
            </Col>
              </Row>*/}
          <Row>
            <Col className='align-right'>
              <button className='btn primary'>Agregar empresa</button>
              {/*<Button className='primary' label='Agregar empresa' action={()=>handleSubmit()} />*/}
            </Col>
          </Row>
        </form>
        </Section>
      </Box>
    </div>
  );
}

export default CreateCompany;