import React, { useState } from 'react';
import { Row, Col, Notification, Input, Button, JS } from 'im-ui-pack';
import './ModalCreateMeet.css';

const meetSessionInit:any = [];

const ModalCreateMeet = (props: any) => {
  const { close, save } = props;
  const [meetSession, setMeetSession] = useState(meetSessionInit);
  const timeDateToday = new Date();
  const time_date_year=timeDateToday.getFullYear();
  const time_date_month=(timeDateToday.getMonth() + 1).toString().padStart(2, "0");
  const time_date_day= timeDateToday.getDate().toString().padStart(2, "0");

  const [formData, setFormData] = useState({
    time_date: `${time_date_year}-${time_date_month}-${time_date_day}`,// `${time_date_year}-${time_date_month}-${time_date_day}`,
    time_start: '10:00',
    time_end: '10:30',
    meet_type: 'fisioterapia'
  });

  const saveModal = () => {
    const data = {
      "meet_time_start": meetSession[0].record_start,
      "meet_time_end": meetSession[meetSession.length-1].record_end,
      "meet_sessions": meetSession,
      "meet_type": formData.meet_type
    }
    save(data);
  }

  const closeModal = () => {
    close();
  }

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name] : event.target.value
    })
  }
  const dateToTimestamp = (time_date:any) => {
    const time_date_unix=time_date.getTime();
    console.log('>>>>>> dateToTimestamp: ', time_date);
    return Math.floor(time_date_unix/1000);
  }
  const handleAddSession = (e) => {
    let meetSessionData = JSON.parse(JSON.stringify(meetSession));
    console.log('1) handleAddSession: ', meetSessionData);
    const date_start = new Date(`${formData.time_date} ${formData.time_start}`);
    const date_start_timestamp = dateToTimestamp(date_start);

    console.log('3) handleAddSession: date_start_timestamp:',date_start_timestamp);
    // const date_end = new Date(`${formData.time_date} ${formData.time_end}`);
    const date_end =  new Date(`${formData.time_date} ${formData.time_end}`);
    const date_end_timestamp = dateToTimestamp(date_end);

    const session_data = {
      "record_id": "0",
      "record_start": date_start_timestamp,
      "record_end": date_end_timestamp
    }
    meetSessionData.push(session_data);
    setMeetSession(meetSessionData);
  } 

  const handleDeleteSession = (e, index: any) => {
    let meetSessionData:any = [];
    meetSession.forEach((item: any,itemIndex: any) => {
      if(itemIndex!==index) 
        meetSessionData.push(item);
    })
    console.log('meetSessionData: ', meetSessionData);
    setMeetSession(meetSessionData);
  }

  return (
    <div className='modal-create-meet'>
      <div className='modal-header'>Agregar cita</div>
      <div className='modal-content'>
      <Row>
        <Col>
          <input type="date" className='inp small' id="start" name="time_date" value={formData.time_date} min="2018-01-01" max="2030-12-31" onChange={(e)=>handleInputChange(e)}/>
        </Col>
        <Col>
          <input type='time' className='inp small' id="time" value={formData.time_start} name="time_start" onChange={(e)=>handleInputChange(e)}/>
        </Col>
        <Col>
          <input type='time' className='inp small' id="time" value={formData.time_end} name="time_end" onChange={(e)=>handleInputChange(e)}/>
        </Col>
        {/*
        <Col>
          <select className='inp' name="meet_type" onChange={(e)=>handleInputChange(e)}>
            <option value="fisioterapia">Fisioterapia</option>
            <option value="nutricion">Nutrición</option>
            <option value="coach">Coach</option>
          </select>
        </Col>
        */}
        <Col>
          <button className='btn btn-small primary' onClick={(e)=>handleAddSession(e)}>Agregar</button>
        </Col>
      </Row>
      
      <div>
        {meetSession.map((item: any, index: any)=>{
          console.log('item.record_start: ',item.record_start);
          return (
            <div key={index} className='div-item'>
              {/*<Row key={index}>*/}
                {/*<Col>{item.record_start} {JS.meetFormat.interval(item.record_start, item.record_end)}</Col><Col><button onClick={(e)=>handleDeleteSession(e, index)}>Delete</button></Col>*/}
                <Col>{JS.meetFormat.interval(item.record_start, item.record_end)}</Col><Col><button className='btn btn-small' onClick={(e)=>handleDeleteSession(e, index)}>Delete</button></Col>
              {/*</Row>*/}
            </div>
          )
        })}
      </div>
      {/*
        <Row>
          <Col>
            <input type="radio" id="html" value="HTML"/>
            <label>Agregar empleados</label>
            <input type="radio" id="html" value="HTML"/>
            <label>Compartir link a empleados</label>
          </Col>
        </Row>
      */}
      </div>  
      <div className='modal-bottom'>
        <Button className='secondary-outline' action={()=>closeModal()} label='Cancelar'/>
        <Button className='primary' action={()=>saveModal()} label='Guardar'/>
      </div>
    </div>
  )
}

export default ModalCreateMeet;
