import React from "react";
import { Chart } from "react-google-charts";

export const data = [
  ["Task", "Hours per Day"],
  ["Dolor de espalda", 20],
  ["Dolor de cabeza", 20],
  ["Sin registrar", 59],
  ["Sin sintomas", 1]
];

export const options = {
  legend: "none",
  /*pieSliceText: "none",*/
  pieStartAngle: 0,
  tooltip: { trigger: "none" },
  slices: {
    0: { color: "#4E73BC" },
    1: { color: "#17BCD9" },
    2: { color: "#85C78E" },
    3: { color: "#E3E3E3" }
  },
};

export default function CircleGraph() {
  return (
    <Chart
      chartType="PieChart"
      data={data}
      options={options}
      width={"100%"}
      height={"600px"}
    />
  );
}