import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, H4, H5, Row, Col, Section, Button } from 'im-ui-pack';
import CircleGraph from '../../../../../components/circleGraph/CircleGraph';

function CompanyReportGeneral() {

  return (
    <div>
      <Row>
        <Col>
      <Box>
        <Row>
          <H4>Informe en proceso</H4>
        </Row>
        <Row>
          <Col>Encuesta: Encuesta saludable 2023</Col>
        </Row>
        <Row>
          <Col>Estado: Finalizado</Col>
        </Row>
        <Row>
          <Col>Comienza: 01/01/2023 a 28/01/2023</Col>
        </Row>
        <Section>
          <Row>
            <Col className='w-20'><H5>Fisio</H5></Col>
            <Col className='w-20'>1- Dolores de espalda</Col>
            <Col className='w-20'>40/200 (20%)</Col>
          </Row>
          <Row>
            <Col className='w-20'></Col>
            <Col className='w-20'>2- Dolores de cabeza</Col>
            <Col className='w-20'>40/200 (20%)</Col>
          </Row>
          <Row>
            <Col className='align-center'>
              <div className='circle-percentage'><CircleGraph></CircleGraph></div>
            </Col>
          </Row>
        </Section>

        <Section>
          <Row>
            <Col className='w-20'><H5>Nutrición</H5></Col>
            <Col className='w-20'>1- Acumulación de líquidos</Col>
            <Col className='w-20'>40/200 (20%)</Col>
          </Row>
          <Row>
            <Col className='w-20'></Col>
            <Col className='w-20'>2- Piel seca</Col>
            <Col className='w-20'>40/200 (20%)</Col>
          </Row>
          <Row>
            <Col className='align-center'>
              <div className='circle-percentage'><CircleGraph></CircleGraph></div>
            </Col>
          </Row>
        </Section>

        <Section>
          <Row>
            <Col className='w-20'><H5>Emocional</H5></Col>
            <Col className='w-20'>1- Sintomas de depresión</Col>
            <Col className='w-20'>40/200 (20%)</Col>
          </Row>
          <Row>
            <Col className='w-20'></Col>
            <Col className='w-20'>2- Ansiedad</Col>
            <Col className='w-20'>40/200 (20%)</Col>
          </Row>
          <Row>
            <Col className='align-center'>
              <div className='circle-percentage'><CircleGraph></CircleGraph></div>
            </Col>
          </Row>
        </Section>
        <Section>
          <Row>
            <Col><H5>Observaciones</H5></Col>
          </Row>
          <Row>
            <Col>No se registran.</Col>
          </Row>
        </Section>
      </Box>
      </Col>
      </Row>
      <Row>
        <Col className='align-right'>
          <div className='btn-group'>
            <Button className='primary' label='Descargar reporte' />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default CompanyReportGeneral;
