import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Section, Row, Col, H2, H5, NavigationBar, NavigationLink, JS } from 'im-ui-pack';
import { request, ContentTypes } from '../../../store/api/api';
import { useSelector } from 'react-redux';
// import items from '../../../../store/items/ItemsReducers';

function Profile() {
  const session = JS.getSession();
  const { account_email } = session.account;
  const { company } = useSelector((state: RootState) => state.default.company);
  const [profile, setProfile] = useState();

  useEffect(() => {
    if(!profile) {
      getProfile();
    }
  });

  async function getProfile() {
    const { company_id } = company;
    const data = {
      "company_id" : company_id
    };
    const endpoint = `/company`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
      console.log('result: ', result);
      setProfile(result);
    } catch (err) {
      console.log(err)
    }
  };

  const getStatus = (status: string) => {
    switch (status) {
      case 'attended':
        return 'Atendido';
        break;
      case 'absent':
        return 'Ausente';
        break;
      case 'not_attended':
        return 'No atendido';
        break;
      default:
        break;
    }
  }

/*
  const getMeetTime = (meet:any) => {
    return meet.map((meetItem)=>{
      return (
        <div>
          <div>{meetItem.time_begin}</div>
          <div>{meetItem.time_end}</div>
        </div>
      );
    });
  }

  const getMeetEmployeeSubscribers = (meet: any) => {
    let total_employee_subscribers = 0;
    meet.forEach(meetItem => {
      total_employee_subscribers += parseFloat(meetItem.employee_subscribers);
    })
    return total_employee_subscribers;
  }

  const getAssessmentSubscription = (item: any) => {
    return (
      <div>
        <div>{item.employee_subscription}/{item.employee_subscription_total}</div>
        <div>{(item.employee_subscription * 100)/item.employee_subscription_total }% Completado</div>
      </div>
    );
  }

  const getTimeBeginEnd = (item: any) => {
    return (
      <div>
        <div>{item.time_begin}</div>
        <div>{item.time_end}</div>
      </div>
    );
  }*/

  return ( 
    <div>
      <Section>
        <Row>
          <Col>
            <H2>Perfil</H2>
            <NavigationBar>
              <NavigationLink href="https://admin.improovia.com/">Admin</NavigationLink>
            </NavigationBar>
          </Col>
          <Col className='align-right'>
          </Col>
        </Row>
      </Section>
      {
        profile ? (
          <div>
            <Section>
              <Row>
                <Col><H5>Responsable</H5></Col>
              </Row>
              <Row>
                <Col className='w-20'>Nombre completo</Col><Col>{profile.company_manager_fullname}</Col>
              </Row>
              <Row>
                <Col className='w-20'>Email</Col><Col>{account_email}</Col>
              </Row>
              <Row>
                <Col className='w-20'>Tel</Col><Col>{profile.company_manager_tel}</Col>
              </Row>
              <Row>
                <Col className='w-20'>Fecha de nacimiento</Col><Col>{profile.company_manager_birthday}</Col>
              </Row>
            </Section>
            <Section>
              <Row>
                <Col><H5>Empresa</H5></Col>
              </Row>
              <Row>
                <Col className='w-20'>Nombre de empresa</Col><Col>{profile.company_name}</Col>
              </Row>
              <Row>
                <Col className='w-20'>Dirección fiscal</Col><Col>{profile.company_location_address}</Col>
              </Row>
              <Row>
                <Col className='w-20'>NIF</Col><Col>{profile.company_tax_id}</Col>
              </Row>
              <Row>
                <Col className='w-20'>Ciudad</Col><Col>{profile.company_location_city}</Col>
              </Row>
              <Row>
                <Col className='w-20'>Provincia</Col><Col>{profile.company_location_state}</Col>
              </Row>
            </Section>
            <Section>
              <Row>
                <Col><H5>Acuerdos y contratos</H5></Col>
              </Row>
              <Row>
                <Col className='w-20'>Email responsable de pagos</Col><Col>{profile.company_contract_email}</Col>
              </Row>
              <Row>
                <Col className='w-20'>Periodo de liquidación</Col><Col>A {profile.company_payment_time} días</Col>
              </Row> {/*
              <Row>
                <Col className='w-20'>Modalidad de pago</Col><Col>{profile.company_payment_type}</Col>
              </Row>
             
              <Row>
                <Col className='w-20'>Documentos</Col><Col><Link to={'/file.pdf'}>Descargar Contrato Improovia.pdf</Link></Col>
              </Row>
              */}
            </Section>
            </div>
        ) : null
      }

    </div>
  );
}

export default Profile;