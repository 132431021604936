import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import { Section, Row, Col, H2, H3, H5, Box, Input, InputCheckbox, Button, NavigationBar, NavigationLink, Notification } from 'im-ui-pack';
import { request, ContentTypes } from '../../../../store/api/api';
import { useSelector } from 'react-redux';
// import items from '../../../../store/items/ItemsReducers';
// import assessment_data from './../../../../store/mock/assessment.json';
import IAssessment from '../../../../models/assessment';
import './CreateAssessment.css';

let arrayAssesment = {
  assessment_title: 'Título',
  assessment_description: 'Descripción',
  assessment_employee: '100',
  assessment_date_start: '',
  assessment_date_end: '',
  assessment_surveys: [
    {
      title: 'Fisioterapia',
      items: [
        {
          label: 'Dolor de espalda'
        },
        {
          label: 'Dolor de cuello'
        }
      ]
    },
    {
      title: 'Nutrición',
      items: [
        {
          label: 'Se alimenta con las 4 comidas'
        },
        {
          label: 'Come usualmente vegetales'
        } 
      ]
    }
  ],
  assessment_commentary: 'Comentario'
}

function CreateAssessmentCompany() {
  const { company } = useSelector((state: RootState) => state.default.company);

  //const [notification, setNotification] = useState({status: false, style: '', message: ''});
  const [formData, setFormData] = useState(arrayAssesment);
  const [formStatus, setFormStatus] = useState();
  useEffect(() => {
    console.log('useEffect: formData',formData);
  });

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name] : event.target.value
    })
  }

  const handleChange = (e, index) => {
    const updated_surveys = formData.assessment_surveys.map((item,id) => {
      if (id === index) {
        item.title = e.target.value;
      }
      return item;
    });
    setFormData({...formData, assessment_surveys: updated_surveys});
  };

  const handleItemsChange = (e, itemIndex, surveyIndex) => {
    const updated_items = formData.assessment_surveys.map((surveyItem, id) => {
      if (id === surveyIndex) {
        surveyItem.items = surveyItem.items.map((item, itemID) => {
          if (itemID === itemIndex) {
            item.label = e.target.value;
          }
          return item;
        });
      }
      return surveyItem;
    });
    setFormData({...formData, assessment_surveys: updated_items});
  };

  /*
  const getAssessmentSubscription = (item: any) => {
    return (
      <div>
        <div>{item.employee_subscription}/{item.employee_subscription_total}</div>
        <div>{(item.employee_subscription * 100)/item.employee_subscription_total }% Completado</div>
      </div>
    );
  }

  const getTimeBeginEnd = (item: any) => {
    return (
      <div>
        <div>{item.time_begin}</div>
        <div>{item.time_end}</div>
      </div>
    );
  }
*/

  const componentItemsEdit = (items, surveyIndex) => {
    return (
      <div>
        <div className='wrapper-checkbox-items'>
          {items.map((item, itemIndex)=> {
            return (
              <Row key={itemIndex}>
                <Col>
                  <div className='flex'>
                    <input type='text' className='input-assessment' value={item.label} onChange={e => handleItemsChange(e, itemIndex, surveyIndex)} />
                  </div>
                </Col>
              </Row>
            )
          })}
          <Row>
            <Col><Button className='secondary-outline' label='+ Agregar item' action={()=>handleAddItem(surveyIndex)}/></Col>
          </Row>
        </div>
      </div>
    )
  }

  const componentSurveysEdit = (surveyItems, index) => {
    const { items, title } = surveyItems;

    return (
      <div>
        <Row>
          <Col>
            <input type='text' className='input-assessment-surveys' value={formData.assessment_surveys[index].title} onChange={e => handleChange(e, index)} />
          </Col>
        </Row>
        <div className='wrapper-checkbox-items'>
          {componentItemsEdit(items, index)}
        </div>
      </div>
    )
  }

  const componentSurveys = (surveyItems, index) => {
    const { items, title } = surveyItems;

    return (
      <div>
        <Row>
          <Col>
            <H5>{title}</H5>
          </Col>
        </Row>
        <div className='wrapper-checkbox-items'>
          {items.map((item, index)=> {
            return (<Row key={index}>
              <Col>
                <div className='flex'>
                  <input type='checkbox' className='space'/> {item.label}
                </div>
              </Col>
            </Row>)
          })}
        </div>
      </div>
    )
  }

  const handleAddSurvey = () => {
    const updated_surveys = formData.assessment_surveys;
    const data = {
      title: 'Título',
      items: [
        {
          label: 'Nuevo'
        },
      ]
    };
    updated_surveys.push(data);
    setFormData({...formData, assessment_surveys: updated_surveys});
  }

  const handleAddItem = (surveyIndex) => {
    //const updated_items = formData.assessment_surveys;
    const data = { label: 'Nuevo' }
    const updated_items = formData.assessment_surveys.map((surveyItem, id) => {
      if (id === surveyIndex) {
        surveyItem.items.push(data);
      }
      return surveyItem;
    });
    setFormData({...formData, assessment_surveys: updated_items});
  }

  async function createAssessment() {
    // event.preventDefault();
    /*const data = {
      "assessment_title": formData.assessment_title
    }*/
    const data = formData;
    const { company_id } = company;
    formData.company_id = company_id;
    const endpoint = `/assessment/create`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const { result } = res.data;
      console.log('result: ', result);
      if(result) {
        setFormStatus('success');
      }
    } catch (err) {
      console.log(err)
    }
  };

  const submitForm = () => {
    createAssessment()
    console.log('submitForm: formData: ', formData);
  }

if (formStatus==='success') {
  return (
    <div>
      <Section>
        <Row>
          <Col>
            <H2>Crear encuesta</H2>
            <NavigationBar>
              <NavigationLink href="https://admin.improovia.com/">Admin</NavigationLink>
              <NavigationLink href="/">Encuestas</NavigationLink>
            </NavigationBar>
          </Col>
          <Col className='align-right'>
          </Col>
        </Row>
        <Row>
          <Col>
            <Notification className={'success'}>El cuestionario se ha creado correctamente.</Notification>
          </Col>
        </Row>
      </Section>
    </div>
  )
}

  return ( 
    <div>
      <Section>
        <Row>
          <Col>
            <H2>Crear encuesta</H2>
            <NavigationBar>
              <NavigationLink href="https://admin.improovia.com/">Admin</NavigationLink>
              <NavigationLink href="/">Encuestas</NavigationLink>
            </NavigationBar>
          </Col>
          <Col className='align-right'>
          </Col>
        </Row>
        <Row>
          <Col>
            <Box>
              <Row>
                <Col>

                  <Row>
                    <Col>
                      <H3>
                        <input
                          type='text'
                          className='input-assessment-title'
                          name='assessment_title'
                          value={formData.assessment_title}
                          placeholder={'Título'}
                          onChange={handleInputChange}
                        />
                      </H3>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <input
                        type="text"
                        className='input-assessment'
                        name='assessment_description'
                        value={formData.assessment_description}
                        placeholder={'Descripción'}
                        onChange={handleInputChange}
                      />
                    </Col>
                  </Row>
                  {formData.assessment_surveys.map((surveyItems,index) => {
                    return componentSurveysEdit(surveyItems, index);
                  })}
                  <Row>
                    <Col><Button className='secondary-outline' label='+ Agregar encuesta' action={()=>handleAddSurvey()}/></Col>
                  </Row>
                  {/*<Row>
                    <Col>
                      <input type='text' className='input-assessment' name='assessment_commentary' value={formData.assessment_commentary} placeholder={'Comentario'} onChange={handleInputChange} />
                    </Col>
                </Row>*/}
                </Col>
                <Col>
                  {/* Preview */}
                  <div className='assessment-preview'>
                    <Box>
                      <Row>
                        <Col>
                          <H3>{formData.assessment_title}</H3>
                        </Col>
                      </Row>
                      <Row>
                        <Col>{formData.assessment_description}</Col>
                      </Row>
                      {formData.assessment_surveys.map((surveyItems,index) => {
                        return componentSurveys(surveyItems, index);
                      })}
                      <Row>
                        <Col>{formData.assessment_commentary}</Col>
                      </Row>
                    </Box>
                  </div>
                </Col>
              </Row>
            </Box>
          </Col>
        </Row>

        <Row>
          <Col className='align-right'>
            <div className='btn-group'>
              <Button className='' label='Cancelar' action={()=>null} />
              <Button className='primary' label='Publicar' action={()=>submitForm()}/>
            </div>
          </Col>
        </Row>
      </Section>
    </div>
  );
}

export default CreateAssessmentCompany;