import React, { useEffect } from 'react';
import SideMenu from '../../components/sideMenu/SideMenu';
import './DashboardLayout.css';
import { Container, HeaderDashboard, JS } from 'im-ui-pack';
import sessionLinks from '../../store/mock/sessionLinks.json';
import { useSelector, useDispatch } from 'react-redux';
import { request, ContentTypes } from '../../store/api/api';
import { setCompany } from '../../store/company/CompanyActions';
import { useHistory } from 'react-router-dom';

// import { getCompany } from '../../store/company/CompanyActions';

// Test session

/*
  const user = {
    account_id: 'mK8J488v7g9JUSSaRDQqVP',
    account_email: 'testc20@gmail.com',
    account_firstname: 'Test c20',
    account_lastname: 'Test c20',
    account_role: 'company'
  };
  
  JS.setSession({ user });
  const session_token = JS.getSession();
  console.log('session_token: ',session_token);
*/
  // JS.setCookie({cname:'session_token', cvalue: session_token});

function DashboardLayout(props: any) {
  const history = useHistory();
  const session = JS.getSession();
  const { company } = useSelector((state: RootState) => state.default.company);
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log('company: ', company);
    // console.log('session: ', session);
    if(company===null) {
      getCompany()
    }
  })

  async function getCompany() {
    const { account_id } = session.account;
    const data = {
      account_id
    };
    const endpoint = `/company/account`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
      console.log('>>>> result: ', result);
      if(result && result.length>0) {
        const company_data = result[0];
        dispatch(setCompany(company_data));
      } else {
        history.push('/company/create')
      }
    } catch (err) {
      console.log(err)
    }
  };

  return (
    <div className="dashboard-layout">
      <HeaderDashboard sessionLinks={sessionLinks}/>
      <SideMenu />
      <div className='main-wrapper'>
        <Container>
          {props.children}
        </Container>
      </div>
    </div>
  );
}

export default DashboardLayout