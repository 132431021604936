import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './SideMenu.css';
import { Icon } from 'im-ui-pack';

function SideMenu() {
  const { device } = useWindowDimensions();
  const [showSideMenu, setShowSideMenu] = useState(false);

  function getWindowDimensions() {
    const { innerWidth: width } = window;
    let device = 'desktop';

    if (width < 768) {
      device = 'mobile';
    } else {
      device = 'desktop';
    }
    return {
      device
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }

  function changeSideMenu(visible: boolean) {
    setShowSideMenu(visible);
  }

  if ((device === 'mobile') && (!showSideMenu)) {
    return (
      <div className='side-menu-btn-open' onClick={() => changeSideMenu(true)}>
        <Icon.IconMenu />
      </div>
    )
  }

  return (
    <div className={`side-menu ${device}`}>
      <div className='side-menu-btn-close' onClick={() => changeSideMenu(false)}><Icon.IconMenu /></div>
    <div>
      <div className='logo-wrapper'>
        <i className='logo'></i>
        <div className='logo-subtitle'>EMPRESA</div>
      </div>
      <div className='menu'>
        <div className='item'>
          <Link to='/dashboard/assessment/branch/1' className='menu-icon-link'>
            <div className='icon'><div className='icon-menu-meet'></div></div>
            <div className='content'>Citas</div>
          </Link>
        </div>
        <div className='item'>
          <Link to='/dashboard' className='menu-icon-link'>
            <div className='icon'><div className='icon-menu-assessments'></div></div>
            <div className='content'>Encuestas</div>
          </Link>
        </div>
        <div className='item'>
          <Link to='/dashboard/report' className='menu-icon-link'>
            <div className='icon'><div className='icon-menu-reports'></div></div>
            <div className='content'>Reporte</div>
          </Link>
        </div>
        {/*<div className='item'>
          <Link to='/dashboard/payment' className='menu-icon-link'>
            <div className='icon'><div className='icon-menu-payments'></div></div>
            <div className='content'>Pagos</div>
          </Link>
        </div>*/}
        <div className='item'>
          <Link to='/dashboard/profile' className='menu-icon-link'>
            <div className='icon'><div className='icon-menu-profile'></div></div>
            <div className='content'>Perfil</div>
          </Link>
        </div>
        {/*
        <div className='item'>
          <Link to='/dashboard/chat' className='menu-icon-link'>
            <div className='icon'><div className='icon-menu-chat'></div></div>
            <div className='content'>Chat</div>
          </Link>
      </div>*/}
      </div>
    </div>
      <div className='text-small'>
        Improovia, Copyright 2023
      </div>
    </div>
  );
}

export default SideMenu