import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Section, Row, Col, H2, Tabs, Tab, Table, Thead, Tbody, Tr, Th, Td, Button } from 'im-ui-pack';
import { requestSwitcherAPI, ContentTypes } from '../../../../store/api/api';
// import items from '../../../../store/items/ItemsReducers';
import employee_data from './../../../../store/mock/employee.json';

function EmployeeOverview() {

  useEffect(() => {});

  return ( 
    <div>
      <Section>
        <Row>
          <Col><H2>Empleados</H2></Col>
          <Col className='align-right'>
          </Col>
        </Row>
        <Row>
          <Col>

            <Table>
              <Thead>
              <Tr>
                <Th>Nombre de profesional</Th><Th>Locación</Th><Th></Th>
              </Tr>
              </Thead>
              <Tbody>
              {
                employee_data.map((item: any)=> {
                  return (
                    <Tr key={item.id}>
                      <Td>{item.firstname} {item.lastname}</Td><Td>{item.location.city}</Td><Td><div className='position-right'><a href='https://professional.improovia.com/dashboard'><Button className='primary' label='Detalle' /></a></div></Td>
                    </Tr>
                  )
                })
              }
              </Tbody>
            </Table>

          </Col>
        </Row>
      </Section>
    </div>
  );
}

export default EmployeeOverview;