// import mockOffices from './../mock/offices.json';

const initialState = {
  company: null,
};

export default function company(state = initialState, action) {
  switch (action.type) {
    case 'SET_COMPANY':
      return {
        ...state,
        company: action.company
      };
    case 'GET_COMPANY':
      return {
        ...state,
        company: action.company
      };
    default:
      return state;
  }
}
