import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Section, Row, Col, H2, Tabs, Tab, Table, Thead, Tbody, Tr, Th, Td, Button, NavigationBar, NavigationLink, JS } from 'im-ui-pack';
import { request, ContentTypes } from '../../../../store/api/api';
// import items from '../../../../store/items/ItemsReducers';
// import assessment_data from './../../../../store/mock/assessment.json';
import { useSelector, useDispatch } from 'react-redux';
import { setCompany } from '../../../../store/company/CompanyActions';
//import  from '../../../../store/items/ItemsActions';

function AssessmentCompany() {
  const [assessments, setAssessments] = useState();
  const { company } = useSelector((state: RootState) => state.default.company);
  // const dispatch = useDispatch();
  useEffect(() => {
    if(company && !assessments) {
      getAssessments();
    }
  });

  const getAssessmentSubscription = (item: any) => {
    return (
      <div>
        <div>0/100</div>
        <div>0% Completado</div>
      </div>
    );
    /*return (
      <div>
        <div>{item.employee_subscription}/{item.employee_subscription_total}</div>
        <div>{(item.employee_subscription * 100)/item.employee_subscription_total }% Completado</div>
      </div>
    );*/
  }

  const getTimeBeginEnd = (item: any) => {
    return (
      <div>
        <div>{JS.dateFormat.timestampToDate(item.assessment_created_at)}</div>
        <div>{JS.dateFormat.timestampToDate(item.assessment_created_at)}</div>
      </div>
    );
  }

  async function getAssessments() {
    // console.log('>>>>> company',company);
    const { company_id } = company;
    const data = {
      "company_id" : company_id
    };
    const endpoint = `/assessment/list`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
      // console.log('result: ', result);
      setAssessments(result);
    } catch (err) {
      console.log(err)
    }
  };

  return ( 
    <div>
      <Section>
        <Row>
          <Col>
            <H2>Encuestas</H2>
            <NavigationBar>
              <NavigationLink href="https://admin.improovia.com/">Admin</NavigationLink>
            </NavigationBar>
          </Col>
          <Col className='align-right'>
            <Link to={'/dashboard/assessment/create/'}><Button className='primary' label='Crear encuesta' action={()=>null} /></Link>
          </Col>
        </Row>
        <Row>
          <Col>

            <Table>
              <Thead>
              <Tr>
                <Th>Título de encuesta</Th>
                {/*<Th>Participación de empleados</Th>*/}
                <Th>Fecha de creación</Th>
                <Th></Th>
              </Tr>
              </Thead>
              <Tbody>
              {assessments ? (
                assessments.map((item: any)=> {
                  return (
                    <Tr key={item.assessment_id}>
                      <Td>{item.assessment_title}</Td>
                      {/*<Td>{getAssessmentSubscription(item)}</Td>*/}
                      <Td>{JS.dateFormat.timestampToDate(item.assessment_created_at) /*getTimeBeginEnd(item)*/}</Td>
                      <Td>
                        <div className='position-right btn-group'>
                          {/*<Button className='' label='Informe' />
                          <Button className='' label='Presupuesto' />*/}
                          <Link to={`/dashboard/assessment/branch/${item.assessment_id}`}>
                            <Button className='primary' label='Puntos de servicio' action={()=>null}/>    
                          </Link>
                        </div>
                      </Td>
                    </Tr>
                  )
                })
              ): null

              }
              </Tbody>
            </Table>

          </Col>
        </Row>
      </Section>
    </div>
  );
}

export default AssessmentCompany;