import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Section, Row, Col, H2, H3, H5, Box, InputCheckbox, Notification, Footer, Button } from 'im-ui-pack';
import { request, ContentTypes } from '../../../../store/api/api';
// import items from '../../../../store/items/ItemsReducers';
import assessment_data from './../../../../store/mock/assessment.json';
import './PublicAssessmentCompany.css';

function PublicAssessmentCompany() {
  const { assessment_id, branch_id } = useParams();
  const [assessment, setAssessment] = useState(null);
  const [assessmentResult, setAssessmentResult] = useState([]);
  const [assessmentIndexed, setAssessmentIndexed] = useState([]);
  const [assessmentSuccess, setAssessmentSuccess] = useState(false);
  const [formData, setFormData] = useState({
  });

  useEffect(() => {
    if(!assessment) {
      getAssessment();
    }
    console.log('assessmentResult: ', assessmentResult)
  });

  async function getAssessment() {
    const data = {
      "assessment_id" : assessment_id
    };
    const endpoint = `/assessment`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
      console.log('result: ',result);
      indexerAssessmentItems(result.assessment_surveys);
      setAssessment(result);
    } catch (err) {
      console.log(err)
    }
  };

  const indexerAssessmentItems = (assessment_surveys: any) => {
    let indexer = 0;
    const assessment_indexed = assessment_surveys.map((surveyItems: any) => {
      const items = surveyItems.items.map((item:any)=>{
        indexer = indexer+1;
        const data = {
          id: indexer,
          label: item.label
        }
        return data;
      })
      const dataResult = {
        title: surveyItems.title,
        items
      }
      return dataResult;
      
    });
    setAssessmentIndexed(assessment_indexed);
  }

  const handleCheck = (item: any) => {
    let assessmentResultUpdated = JSON.parse(JSON.stringify(assessmentResult));

    if(assessmentResultUpdated.find((itemResult)=>itemResult.id===item.id)) {
      assessmentResultUpdated = assessmentResultUpdated.filter(function( obj ) {
        return obj.id !== item.id;
      });
    } else {
      const data = {
        id: item.id,
        label: item.label
      }
      assessmentResultUpdated.push(data);      
    }
    setAssessmentResult(assessmentResultUpdated);
  }

  async function handleSubmit() {
    const data = {
      "assessment_id" : assessment_id,
      "company_id": "0",
      "branch_id": branch_id,
      "assessment_records_data": assessmentResult
    };
    const endpoint = `/assessment_results/create`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
      console.log('assessment_results: result: ',result);
      setAssessmentSuccess(true);
    } catch (err) {
      console.log(err)
    }
  };

  const componentSurveys = (surveyItems:any, index: string) => {
    const { items, title } = surveyItems;

    return (
      <div key={index}>
        <Row>
          <Col>
            <H5>{title}</H5>
          </Col>
        </Row>
        <div className='wrapper-checkbox-items'>
          {items.map((item: any, index:any)=> {
            return (<Row key={item.id}>
              <Col>
                <div className='flex'>
                  <input className='check-item' type='checkbox' name={index} onClick={()=>handleCheck(item)}/> {item.id} {item.label}
                </div>
              </Col>
            </Row>)
          })}
        </div>
      </div>
    )
  }

  if(assessmentSuccess) {
    return (
      <div>
        <Section className='medium'>
          <Row>
            <Col className='text-align-center'>
              <H3>{assessment.assessment_title}</H3>
            </Col>
          </Row>
          <Box className=''>
            <Row>
              <Col>
                <Notification className='success'>La encuesta se ha enviado correctamente. ¡Gracias por participar!</Notification>
              </Col>
            </Row>
          </Box>
        </Section>
      </div>
    )
  }

  if(!assessment) {
    return (<div>Abriendo encuesta...</div>);
  }

  return ( 
    <div>
      <Section className='medium'>
        <Row>
          <Col className='text-align-center'>
            <H3>{assessment.assessment_title}</H3>
          </Col>
        </Row>
        <Box className=''>
          <Row>
            <Col>{assessment.assessment_description}</Col>
          </Row>
          {assessmentIndexed.map((surveyItems, index) => {
            return componentSurveys(surveyItems, index);
          })}
          <Row>
            <Col>
              <H5>Observaciones</H5>
            </Col>
          </Row>
          <Row>
            <Col>
              <textarea placeholder='Comentario'>{assessment.assessment_commentary}</textarea>
            </Col>
          </Row>
          <Row>
            <Col>{assessment.assessment_commentary}</Col>
          </Row>
          <Row>
            <Col className='align-right'>
              <Button className='primary' label='Enviar encuesta' action={()=>handleSubmit()} />
            </Col>
          </Row>
        </Box>
      </Section>
      <Footer />
    </div>
  );
}

export default PublicAssessmentCompany;