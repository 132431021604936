import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Section, Row, Col, H2, Tabs, Tab, Table, Thead, Tbody, Tr, Th, Td, Button, JS, NavigationBar, NavigationLink } from 'im-ui-pack';
import { requestSwitcherAPI, ContentTypes } from '../../../../store/api/api';
// import items from '../../../../store/items/ItemsReducers';
import branch_assessment_data from './../../../../store/mock/branch_assessment.json';

function BranchPayment() {
  useEffect(() => {});

  const getMeetTimeToPeriod = (meet:any) => {
    return meet.map((meetItem)=>{
      return (
        <div>
          <div>{JS.dateFormat.dateToMonth(meetItem.time_end)}</div>
        </div>
      );
    });
  }

  const getMeetEmployeeSubscribers = (meet: any) => {
    let total_employee_subscribers = 0;
    meet.forEach(meetItem => {
      total_employee_subscribers += parseFloat(meetItem.employee_subscribers);
    })
    return total_employee_subscribers;
  }

  const getAmount = (meet: any) => {
    let total_amount = 0;
    meet.forEach(meetItem => {
      total_amount += parseFloat(meetItem.amount);
    })
    return total_amount;
  }

  return ( 
    <div>
      <Section>
        <Row>
          <Col>
            <H2>Pagos</H2>
            <NavigationBar>
              <NavigationLink href="https://admin.improovia.com/">Admin</NavigationLink>
            </NavigationBar>
          </Col>
          <Col className='align-right'>
          </Col>
        </Row>
        <Row>
          <Col>

            <Table>
              <Thead>
              <Tr>
                <Th>Sucursal</Th><Th>Periodo</Th><Th>Número de sesiones</Th><Th>Monto a pagar</Th><Th>Pago</Th><Th></Th>
              </Tr>
              </Thead>
              <Tbody>
              {
                branch_assessment_data.map((item: any)=> {
                  return (
                    <Tr key={item.assessment_id}>
                      <Td>{item.name}</Td>
                      <Td>{getMeetTimeToPeriod(item.meet)}</Td>
                      <Td>{getMeetEmployeeSubscribers(item.meet)}</Td>
                      <Td>€{getAmount(item.meet)}</Td>
                      <Td>Pendiente</Td>
                      <Td>
                        <div className='position-right btn-group'>
                          <Button className='primary' label='Descargar factura' action={()=>null}/>
                          <Button className='primary' label='Detalle' action={()=>null}/>
                        </div>
                      </Td>
                    </Tr>
                  )
                })
              }
              </Tbody>
            </Table>

          </Col>
        </Row>
      </Section>
    </div>
  );
}

export default BranchPayment;