const setCompany = function (company) {
  return {
    type: 'SET_COMPANY',
    company
  };
};

const getCompany = function (company) {
  return {
    type: 'GET_COMPANY',
    company: {
      company_id: '111111'
    }
  };
};


export { setCompany, getCompany };
